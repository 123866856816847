import styled from '@emotion/styled';
import { Button } from 'components/atoms/button';
import { EnHeading } from 'components/atoms/enHeading';
import { ContentWrapper } from 'components/organisms/contentWrapper';
import React, { useEffect } from 'react';
import { TextSet } from 'components/molecules/textSet/index';
import { JaHeading } from 'components/atoms/jaHeading';
import { Text } from 'components/atoms/text/index';
import { ContentInner } from 'components/organisms/contentInner/index';
import ParticleImageManager from 'components/organisms/particleCanvas/ParticleImageManager';
import sizes from 'style/sizes';
import { mq } from 'style/variables';
import { graphql } from 'gatsby';
import SEO from 'util/seo';
import FadeUpSection from 'components/organisms/fadeUpSection';

// Please note that you can use https://github.com/dotansimha/graphql-code-generator
// to generate all types from graphQL schema
interface IndexPageProps {
  data: {
    site: {
      siteMetadata: {
        title: string;
      };
    };
  };
}

const TitleSection = styled('div')`
  width: 100%;
  ${mq.onlypc} {
    padding-top: ${sizes.margin[180]};
    padding-bottom: ${sizes.margin[100]};
  }
  ${mq.onlysp} {
    padding-top: ${sizes.margin[155]};
    padding-bottom: ${sizes.margin[64]};
  }
`;

const ContactWrap = styled.div`
  padding-bottom: ${sizes.margin[180]};
  ${mq.onlysp} {
    padding-bottom: ${sizes.margin[120]};
  }
`;
const ContactItem = styled.div`
  &:not(:last-of-type) {
    padding-bottom: ${sizes.margin[180]};
  }
  ${mq.onlysp} {
    &:not(:last-of-type) {
      padding-bottom: ${sizes.margin[120]};
    }
  }
`;
const ContactTexts = styled(TextSet)`
  margin-bottom: 0;
  ${mq.onlysp} {
    margin-bottom: 0;
  }
  * + & {
    margin-top: ${sizes.margin[48]};
  }
  p + p {
    ${mq.onlysp} {
      margin-top: ${sizes.margin[48]};
    }
  }
  * + a {
    margin-top: 24px;
    ${mq.onlysp} {
      width: 251px;
      height: 47px;
      margin-left: auto;
      margin-right: auto;
    }
  }
`;
const ContactButtons = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-top: ${sizes.margin[56]};
  ${mq.onlysp} {
    margin-top: ${sizes.margin[44]};
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  > a {
    ${mq.onlysp} {
      width: 251px;
      height: 47px;
    }
    & + a {
      ${mq.onlypc} {
        margin-left: 15px;
      }
      ${mq.onlysp} {
        margin-top: 15px;
      }
    }
  }
`;

const Pages: React.FC<IndexPageProps> = () => {
  useEffect(() => {
    ParticleImageManager.loadAll().then(() => {
      ParticleImageManager.generateAll().then(() => {
        ParticleImageManager.start();
      });
    });

    return () => {
      ParticleImageManager.delete();
    };
  }, []);

  return (
    <>
      <SEO title={'Contact'} />
      <ContentWrapper>
        <TitleSection>
          <EnHeading>Contact</EnHeading>
        </TitleSection>
        <ContentInner>
          <ContactWrap>
            <ContactItem>
              <FadeUpSection>
                <ContactTexts>
                  <JaHeading>お仕事のご依頼やご相談</JaHeading>
                  <Text>
                    ビジネスデザイン、CX改善、MVVデザイン、EX(従業員体験)デザイン、ブランディングなどのご依頼やご相談は、こちらからお願いいたします。
                    <br />
                    初期構想段階など具体的なことが決まっていない場合でも、お気軽にご相談ください。
                  </Text>
                </ContactTexts>
                <ContactButtons>
                  <Button href={'https://4dsd.svy.ooo/ng/answers/f292399df3d1c1aa81fe276480051c/'} blank type={'fill'}>
                    Contact
                  </Button>
                </ContactButtons>
              </FadeUpSection>
            </ContactItem>

            <ContactItem>
              <FadeUpSection>
                <ContactTexts>
                  <JaHeading>採用について</JaHeading>
                  <Text>
                    UXデザイナー・デザイナー・エンジニア・管理部門まで、フォーデジットでは各ポジションで私たちの活動やデザインへの向き合い方に共感いただける方を募集しています。
                  </Text>
                  <ContactButtons>
                    <Button href={'https://recruit.4digit.com/'} blank>
                      Recruit Site
                    </Button>
                  </ContactButtons>
                </ContactTexts>
              </FadeUpSection>
            </ContactItem>

            <ContactItem>
              <FadeUpSection>
                <ContactTexts>
                  <JaHeading>リリース・広報に関するお問い合わせ</JaHeading>
                  <Text>
                    リリースに関するお問い合わせ、取材依頼、オフィススペースの利用依頼、
                    その他会社やチームに対するご意見などはこちらからご連絡ください。
                  </Text>
                </ContactTexts>
                <ContactButtons>
                  <Button href={'https://4dsd.svy.ooo/ng/answers/0c3da392aa3776baa9821d3b1c2806/'} blank type={'fill'}>
                    Contact
                  </Button>
                </ContactButtons>
              </FadeUpSection>
            </ContactItem>
          </ContactWrap>
        </ContentInner>
      </ContentWrapper>
    </>
  );
};

export default Pages;

export const pageQuery = graphql`
  query ContactIndex {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
